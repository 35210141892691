.home {
  height: 88vh;
}
.showcase {
  height: 50%;
  display: block;
  background-image: url("../../assets/img/banner.png");

  .bg-text {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: bold;
    border: 3px solid #f1f1f1;
    z-index: 2;
    padding: 20px;
    text-align: center;
  }
  .title {
    color: violet;
  }
  .subtitle {
    font-size: 1.3rem;
  }
}

.about {
  margin-top: 10rem;
  .aboutimg {
    display: flex;
    justify-content: center;
    .about-img {
      border-radius: 16px;
    }
  }

  .subtitle {
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .title {
    color: violet;
    font-weight: bold;
  }
}

.banner {
  margin-top: 10rem;
}

.story {
  margin-top: 4rem;

  .content {
    margin-top: 5rem;
  }

  .subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: 2rem;
  }
  .title {
    color: violet;
    font-weight: bold;
  }
}

.roadmap {
  margin-top: 8rem;
  .title {
    color: violet;
    font-weight: bold;
  }
  .text-wrapper {
    margin-top: 2rem;

    .number-text {
      color: #61dafb;
      font-size: 2rem;
      font-weight: bold;
    }
    .subtitle {
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
}

.team {
  margin-top: 8rem;

  .team-img {
    border-radius: 10px;
  }

  .sub {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .subtitle {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .title {
    color: violet;
    font-weight: bold;
  }
}

.btn-purple {
  background-color: violet;
  color: #000;
}

.mobile {
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold;
}

.about-logo {
  height: 80px;
  border-radius: 21px;
}

.cus-nav {
  background-color: transparent;
  color: #fff;

  .link-style {
    color: #fff !important;
    margin-left: 1rem;
    font-weight: bold;
  }

  .nav-logo {
    height: 70px;
    border-radius: 50%;
  }
}

.linkh {
  color: #666;
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
}
.linkh:after {
  display: block;
  content: "";
  border-bottom: solid 3px #fff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.linkh:hover:after {
  transform: scaleX(1);
}
.linkh.fromRight:after {
  transform-origin: 100% 50%;
}
.linkh.fromLeft:after {
  transform-origin: 0% 50%;
}

.mint {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 2rem;
  .mint-content {
    border: 3px solid #61dafb;
    border-radius: 10px;
    width: 45%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .mobile {
    font-size: 1.125rem;
    text-align: center;
    font-weight: bold;
  }

  .home {
    height: auto !important;
  }
  .showcase {
    height: 300px;

    .showcase-img {
      height: 300px;
    }
    .img-col {
      justify-content: center;
    }
    .title {
      color: violet;
      font-size: 1.3rem;
    }
    .subtitle {
      font-size: 1rem;
    }
  }

  .about {
    margin-top: 5rem;
    .aboutimg {
      margin-top: 2rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .about-text {
      margin-top: 3rem;
      .title {
        color: violet;
        font-size: 1.9rem;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
      }
      .subtitle {
        font-size: 1.125rem;
        text-align: center;
      }
    }
  }

  .story {
    margin-top: 5rem;

    .title {
      color: violet;
      font-size: 1.9rem;
      font-weight: bold;
      text-align: center;
    }
    .subtitle {
      font-size: 1.125rem;
      text-align: center;
      line-height: 2.3rem;
    }
  }

  .roadmap {
    margin-top: 5rem;

    .text-wrapper {
      margin-top: 2rem;

      .subtitle {
        font-size: 1.125rem;
        line-height: 2.3rem;
      }
    }
  }

  .team-img {
    margin-top: 1rem;
  }

  .mint {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto !important;
    .mint-content {
      padding: 0.5rem;
      margin-top: 2rem;
      border: 3px solid #61dafb;
      border-radius: 10px;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;

      .rm-1 {
        margin-top: 1rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .about {
    margin-top: 5rem;
    .aboutimg {
      margin-top: 2rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .about-text {
      margin-top: 3rem;
    }
  }
}
