* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #000;
  color: #fff;
  /* font-family: inherit; */
  /* font-family: Century Gothic; */
  font-family: Helvetica;
}

.btn-pri {
  background-color: #61dafb;
}
.text-pri {
  color: #61dafb !important;
}

.nav-logo {
  height: 70px;
  border-radius: 50%;
}
